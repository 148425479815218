:root {
    // Colors
    --color-white: 255, 255, 255;
    --color-off-white: 188, 184, 194;
    --color-light-grey: 123, 118, 138;
    --color-light-grey-disabled: 163, 159, 173;
    --color-mid-grey: 237, 237, 239;
    --color-dark-grey-disabled: 99, 92, 116;
    --color-dark-grey: 246, 246, 247;
    --color-black: 0, 0, 0;
    --color-primary: 22, 230, 181; // THIS IS A FALLBACK. PLEASE CONFIGURE INSIDE /config/theme.ts
    --color-state-error: 231, 102, 85; // THIS IS A FALLBACK. PLEASE CONFIGURE INSIDE /config/theme.ts
    --color-state-success: 22, 230, 181; // THIS IS A FALLBACK. PLEASE CONFIGURE INSIDE /config/theme.ts

    // Borders
    --border-radius-regular: 8px;
    --border-radius-large: 22px;

    // Fonts
    --font-family-primary: 'Helvetica';
    --font-family-secondary: 'Ubuntu Mono';

    --font-size-h1: 80px;
    --font-size-h2: 18px;
    --font-size-h3: 14px;
    --font-size-body: 16px;

    --font-weight-bold: 700;
    --font-weight-semiBold: 600;
    --font-weight-medium: 500;
    --font-weight-regular: 400;
    --font-weight-light: 300;

    // Spacing
    --spacing-xxsmall: .25rem;
    --spacing-xsmall: .5rem;
    --spacing-small: .75rem;
    --spacing-regular: 1rem;
    --spacing-large: 1.25rem;
    --spacing-xlarge: 1.5rem;
    --spacing-xxlarge: 2.5rem;
}