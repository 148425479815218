.button {
    appearance: none;
    background-color: transparent;
    border-radius: var(--border-radius-large);
    border: 2px solid rgb(var(--color-primary));
    color: rgb(var(--color-white));
    cursor: pointer;
    font-family: Luna;
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-semiBold);
    min-height: 50px;
    padding: var(--spacing-small);
    width: 100%;

    &:disabled {
        border: 2px solid rgb(var(--color-dark-grey-disabled));
        color: rgb(var(--color-light-grey-disabled));
        cursor: not-allowed;
    }
}

.modal {
    max-width: 500px;
    width: 100%;
}

.moonPayFrame {
    border: 0;
    height: 100vh;
    max-height: 600px;
    width: 100%;
}