body {
    background-color: rgb(var(--color-black));
    font-family: var(--font-family-primary);
}

p {
    margin: 0;
    padding: 0;
    font-family: Luna;
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-body);
    line-height: 26px;
}

h1 {
    font-size: var(--font-size-h1);
    font-family: Luna-Condensed;
    font-weight: var(--font-weight-bold);
    line-height: 72px;
}

h2 {
    font-size: var(--font-size-h2);
    font-family: Luna;
    font-weight: var(--font-weight-semiBold);
    line-height: 28px;
}

h3 {
    font-size: var(--font-size-h3);
    font-family: Luna-Expanded;
    font-weight: var(--font-weight-medium);
    line-height: 20px;
}

// Enforce full page layout from the root

html,
body,
#root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

html {
    min-height: 100vh;
}