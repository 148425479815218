.modalWrapActive {
    visibility: visible;
}

.modalWrapClose {
    visibility: hidden;
}

.modalWrap {
    align-items: center;
    background-color: rgba(var(--color-black),0.75);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    opacity: 1;
    padding: var(--spacing-regular);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100000;
}

.modalHeaderWrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-xlarge);
}

.modal {
    background-color: rgb(var(--color-white));
    border-radius: var(--border-radius-regular);
    max-width: 564px;
    opacity: 0;
    padding: var(--spacing-large) var(--spacing-xxlarge);
    transform: translateY(20px);
    transition: all .3s ease-in-out;
    visibility: hidden;
}

.modalActive {
    opacity: 1;
    transform: translateY(0px);
    visibility: visible;
}

.modalHeader {
    margin: 0;
}

.closeIcon {
    cursor: pointer;
    margin-left: auto;
}