.loader {
    width: 20px;
    height: 20px;
    border: 3px solid rgb(var(--color-black));
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loaderLight {
    border: 3px solid rgb(var(--color-white));
    border-bottom-color: transparent;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}